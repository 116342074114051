import { MDXRenderer } from 'gatsby-plugin-mdx';
import * as React from 'react';
import Default from '../components/default';
import { jsx as ___EmotionJSX } from "@emotion/react";
var ExtraLinks = function ExtraLinks(_ref) { var partners = _ref.partners, siteUrl = _ref.siteUrl, siteName = _ref.siteName, sourceUrl = _ref.sourceUrl; return ___EmotionJSX(React.Fragment, null, partners ? ___EmotionJSX("p", null, "I built this project with", ' ', partners.map(function (partner, i) { return ___EmotionJSX(React.Fragment, { key: partner.ref }, partners.length > 1 && i === partners.length - 1 ? ' and ' : null, ___EmotionJSX("a", { href: partner.url }, partner.name), i === partners.length - 1 ? '.' : null, partners.length > 2 && i !== partners.length - 1 ? ',' : null); })) : null, siteUrl ? ___EmotionJSX("p", null, "You can see it in action ", siteName ? 'at: ' : null, ___EmotionJSX("a", { href: siteUrl }, siteName || 'here'), ".") : null, sourceUrl ? ___EmotionJSX("p", null, "You can view the code for this project ", ___EmotionJSX("a", { href: sourceUrl }, "here"), ".") : null); };
var templates = { blog: Default, projects: Default };
var BlogPost = function BlogPost(_ref2) { var data = _ref2.data; if (!data.mdx || !data.mdx.frontmatter || !data.mdx.body) {
    return ___EmotionJSX(Default, { article: true, pageTitle: "ERROR" }, "Unable to render blog post. Expected data is not present.");
} var _data$mdx = data.mdx, frontmatter = _data$mdx.frontmatter, body = _data$mdx.body, fields = _data$mdx.fields; var Component = templates.blog; if (fields && fields.source && fields.source in templates) {
    Component = templates[fields.source];
} return ___EmotionJSX(Component, { article: true, pageTitle: fields.title, subtitle: frontmatter.subtitle || undefined, date: fields.date, headerImg: frontmatter.heroImage }, ___EmotionJSX(MDXRenderer, null, body), ___EmotionJSX(ExtraLinks, frontmatter)); };
var query = "3781144956";
export default BlogPost;
